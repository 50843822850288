@import 'mobi.css';
@import 'mobi-plugin-color/dist/mobi-plugin-color.css';
@import 'mobi-plugin-prism/dist/mobi-plugin-prism.css';

/* header */
.site-header {
    position: fixed;
    width: 100%;
    background: white;
    padding-top: 15px;
    top: 0;
    left: 0;
}

/* desktop aside */
.site-aside-desktop-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    padding-top: 54px;
    top: 0;
    left: 0;
}

/* mobilde aside */
.site-aside-toggle-button {
    display: inline-block;
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    margin: 15px;
    line-height: 1px;
}

.site-aside-mobile-wrapper {
    display: block;
    background-color: #f2f2f2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    padding-top: 54px;
    margin-left: -100%;
    transition: margin-left 300ms;
}

#site-aside-toggle-checkbox:checked ~ .site-aside-mobile-wrapper {
    margin-left: 0;
}

/* menu list */
.site-menu-list {
    padding-bottom: 15px;
}

.site-menu-list,
.site-menu-list ul {
    list-style: none;
    padding-left: 15px;
}

.site-aside-mobile-wrapper .site-menu-list {
    margin-left: 15px;
    margin-right: 15px;
    padding-right: 15px;
}

/* article */
.site-article-wrapper {
    margin-top: 54px;
    overflow: hidden;
}

/* text-plain */
.site-text-plain {
    font-weight: normal;
}

a.site-text-plain {
    color: #777;
    text-decoration: none;
}

a.site-text-plain:hover,
a.site-text-plain.site-side-title,
a.site-text-plain.current {
    color: #333;
}

a.site-text-plain.current {
    font-weight: 600;
}

/* z-index */
.site-header {
    z-index: 10;
}

.site-article {
    z-index: 1;
}

.site-aside-mobile-wrapper {
    z-index: 20;
}

.site-aside-toggle-button {
    z-index: 30;
}

/* utils */
.site-height-0 {
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.site-height-100 {
    height: 100%;
}

.site-padding-bottom-0 {
    padding-bottom: 0;
}

.site-link-faded {
    opacity: 0.7;
}

.site-link-faded:hover {
    opacity: 1;
}

.site-box {
    border: 1px solid hsla(120, 40%, 55%, 0.3);
    background-color: hsla(120, 40%, 55%, 0.15);
}
